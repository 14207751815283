/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface Sidebar {
  active: boolean;
  activeSm: boolean;
}

const initialState: Sidebar = {
  active: true,
  activeSm: false,
};

const sidebar = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    setSideBar(state, action: PayloadAction<boolean>) {
      state.active = action.payload;
    },
    setSideBarSm(state, action: PayloadAction<boolean>) {
      state.activeSm = action.payload;
    },
  },
});

export const { setSideBar } = sidebar.actions;
export const { setSideBarSm } = sidebar.actions;

export default sidebar.reducer;
