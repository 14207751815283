/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import { api } from '../../api';
import { GetHandbookResponse } from '../../api/responses/get-handbook.response';
import { ApiRoutes } from '../../api/api-routes.enum';
import { Handbook } from '../../types/handbook.interface';
import { finishLoading, startLoading, throwLoading } from './toasts';

interface State {
  items: [Handbook] | null;
}

const initialState: State = {
  items: null,
};

const handbook = createSlice({
  name: 'handbook',
  initialState,
  reducers: {
    fetchItemsStart(state) {
      state.items = null;
    },
    fetchItemsSuccess(state, action: PayloadAction<any>) {
      state.items = action.payload.data;
    },
  },
});

export const { fetchItemsStart, fetchItemsSuccess} = handbook.actions;

export default handbook.reducer;

export const fetchItems = (): AppThunk => async dispatch => {
  try {
    dispatch(startLoading());
    dispatch(fetchItemsStart())
    const { data } = await api.get<GetHandbookResponse>(ApiRoutes.handbook)
    dispatch(fetchItemsSuccess(data));
    dispatch(finishLoading());
  } catch (err) {
    dispatch(throwLoading(err));
  }
}

