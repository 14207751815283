/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ApiRoutes } from '../../api/api-routes.enum';

interface State {
  uncommittedRequest: boolean;
  newPrescriptionData: null | any;
}

const initialState: State = {
  uncommittedRequest: false,
  newPrescriptionData: null,
};

const prescriptions = createSlice({
  name: 'prescriptions',
  initialState,
  reducers: {
    createPrescriptionRequest: {
      reducer: state => {
        state.uncommittedRequest = true;
      },
      prepare: payload => ({
        payload,
        meta: {
          offline: {
            effect: { url: ApiRoutes.prescriptions, method: 'POST', data: {...payload} },
            commit: { type: 'prescriptions/createPrescriptionCommit' },
            rollback: { type: 'prescriptions/createPrescriptionRollback' }
          }
        }
      })
    },
    createPrescriptionCommit(state, action: PayloadAction<any>) {
      state.uncommittedRequest = false;
      state.newPrescriptionData = action.payload.data;
    },
    createPrescriptionRollback(state) {
      state.uncommittedRequest = false;
    },
    resetNewPrescription(state) {
      state.uncommittedRequest = false;
      state.newPrescriptionData = null;
    },
  },
});

export const { createPrescriptionRequest, resetNewPrescription } = prescriptions.actions;

export default prescriptions.reducer;

