import React, { useContext } from 'react';
import { Redirect, RedirectProps, Route, RouteProps } from 'react-router-dom';

import { Routes } from '../../enum/routes.enum';
import { AbilityContext } from '../Can';

interface Props extends RouteProps {
  condition: boolean;
  redirect?: RedirectProps['to'];
}

export const ProtectedRoute: React.FC<Props> = ({
  condition,
  path,
  redirect = '/login',
  component,
  ...props
}) => {
  const ability = useContext(AbilityContext);
  return condition && ability.can('view', path) ? (
    <Route path={path} component={component} {...props} />
  ) : (
    <Redirect to={ability.can('view', redirect) ? redirect : Routes.Profile} />
  );
};
