import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import { AppPush } from '../../types/app-push-interface';

// TODO types
export const selectNotifications = (state: any) => state.notifications;

export const selectItems = createSelector(
  [selectNotifications],
  state => state.items,
);

export const selectUnreadItems = createSelector(
  [selectItems],
  (items: [AppPush]) => items ? items.filter(item => !item.is_read) : items,
);

export const selectItemsWithFormattedDate = createSelector(
  [selectItems],
  (items: [AppPush]) => (items ? items.map(item => ({
      ...item,
      updated_at: moment(item.updated_at).format('DD-MM-YYYY')
    })): items),
);