import 'antd/dist/antd.css';
import 'react-image-crop/lib/ReactCrop.scss';

import './index.css';
import './assets/fonts/ProximaNova-Regular.ttf';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import App from './components/App';
import store from './store/store';

const render = (Component: React.FC) => {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <Component />
      </BrowserRouter>
    </Provider>,
    document.getElementById('root'),
  );
};

render(App);

if (module.hot) {
  module.hot.accept('./components/App', () => {
    // eslint-disable-next-line global-require
    const NextApp = require('./components/App').default;
    render(NextApp);
  });
}

serviceWorkerRegistration.register();