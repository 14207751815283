/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Auth } from '../../api';
import { User as AppUser } from '../../api/responses/signin.response';

interface User {
  fullname: string;
  authorized: boolean;
  userData: AppUser | null;
}

const initialState: User = {
  fullname: 'Иванов Иван Иваныч',
  authorized: Auth.authorized,
  userData: Auth.userData,
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setFullname(state, action: PayloadAction<string>) {
      state.fullname = action.payload;
    },
    setAuthorized(state, action: PayloadAction<boolean>) {
      state.authorized = action.payload;
    },
    setUser(state, action: PayloadAction<AppUser>) {
      state.userData = action.payload;
    },
  },
});

export const { setFullname, setAuthorized, setUser } = user.actions;

export default user.reducer;
