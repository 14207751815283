import { createMuiTheme } from '@material-ui/core';

const breakpointsOverrides = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
});

export const returnLayoutOverridesStyles = (activeSideBar: boolean) => {
  return createMuiTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1024,
        lg: 1280,
        xl: 1920,
      },
    },
    typography: {
      fontFamily: 'ProximaNova',
      fontSize: 14,
      h6: {
        fontWeight: 600,
        fontSize: 23,
      },
    },
    palette: {
      primary: { main: '#045194' },
      secondary: { main: '#F96501' },
      text: {
        primary: '#333333',
        secondary: '#586475',
      },
      action: {
        active: '#586475',
        hover: '#F6F7FB',
      },
    },

    overrides: {
      MuiContainer: {
        maxWidthLg: {
          [breakpointsOverrides.breakpoints.up('sm')]: {
            margin: activeSideBar
              ? '0 20px 5px 262px !important'
              : '0 20px 5px !important',
            maxWidth: 'none',
          },
          [breakpointsOverrides.breakpoints.down('sm')]: {
            margin: '0 10px 30px 10px !important',
          },
          width: 'auto',
        },
        maxWidthMd: {
          [breakpointsOverrides.breakpoints.up('sm')]: {
            margin: activeSideBar
              ? '0 20px 5px 262px !important'
              : '0 20px 5px !important',
            maxWidth: '1100px !important',
          },
          [breakpointsOverrides.breakpoints.down('sm')]: {
            margin: '0 10px 30px 10px !important',
          },
          width: 'auto',
        },
        root: {
          border: 'none',
        },
      },
      MuiAppBar: {
        root: {
          [breakpointsOverrides.breakpoints.up('sm')]: {
            maxWidth: 'none',
            margin: activeSideBar
              ? '0 20px 0 262px !important'
              : '0 20px !important',
          },
          [breakpointsOverrides.breakpoints.down('sm')]: {
            margin: '0 10px !important',
          },
          width: 'auto',
        },
        colorPrimary: {
          margin: '0 !important',
        },
      },
      MuiDrawer: {
        paper: {
          height: 'auto',
          zIndex: 0,
        },
      },
      MuiListItemText: {
        primary: {
          fontWeight: 600,
          fontSize: 14,
        },
      },
      MuiListItemIcon: {
        root: {
          color: '#586475',
        },
      },
      MuiPaper: {
        root: {
          backgroundColor: 'white',
          borderRadius: 10,
          boxShadow: '0px 10px 20px #DADADB',
        },
        rounded: {
          borderRadius: 10,
        },
      },
      MuiCssBaseline: {
        '@global': {
          '*::-webkit-scrollbar': {
            width: 8,
            paddingLeft: 10,
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: '#90A3C8',
            width: 8,
            boxShadow: '0px 4px 10px rgba(188, 205, 220, 0.2)',
            borderRadius: 4,
            transform: 'rotate(-180deg)',
            paddingLeft: 10,
          },
        },
      },
      MuiTable: {
        root: {
          borderRadius: 8,
        },
      },
      MuiTableRow: {
        root: {
          boxShadow: '0px 4px 10px rgba(188, 205, 220, 0.2)',
          backgroundColor: 'transparent',
        },
      },
      MuiTableCell: {
        head: {
          padding: '14px 17px',
          color: '#586475',
        },
        root: {
          backgroundColor: 'white',
        },
      },
      MuiTablePagination: {
        input: {
          background: '#FFFFFF',
          boxShadow: '0px 4px 10px rgba(188, 205, 220, 0.2)',
          borderRadius: 4,
          height: 24,
          alignItems: 'left',
        },
        select: {
          paddingRight: '31px !important',
        },
      },
      MuiButton: {
        contained: {
          boxShadow: '0px 4px 7px rgba(160, 202, 240, 0.62)',
          borderRadius: 34,
          padding: '10px 30px',
          width: 'max-content',
          height: 38,
          textTransform: 'none',
          fontWeight: 600,
        },
      },
      MuiFormControl: {
        root: {
          borderRadius: 8,
        },
      },
      MuiInputBase: {
        input: {
          fontSize: 14,
          borderRadius: 8,
        },
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: 8,
        },
      },
    },
  });
};
