export const firebaseConfig = {
  apiKey: 'AIzaSyApnu-QziXJ9QuLlJcyYnU-EqRLyjEl8To',
  authDomain: 'smartcontrol-5acb9.firebaseapp.com',
  projectId: 'smartcontrol-5acb9',
  storageBucket: 'smartcontrol-5acb9.appspot.com',
  messagingSenderId: '1073351782823',
  appId: '1:1073351782823:web:dd8d489caf871739974682',
  measurementId: 'G-H7CQQFQCG4',
};

export const vapidKey =
  'BHwWpPv0NG2_8BPRsi7WPRPkGDBcPVg9X9ts5eaQld-p7rEd2PveZZDeXC1ib8dyaliSbDTmmpkvoi1WB_pO830';
