import { offline } from '@redux-offline/redux-offline';
import defaultOfflineConfig from '@redux-offline/redux-offline/lib/defaults';
import { OfflineState } from '@redux-offline/redux-offline/lib/types';
import {
  Action,
  StoreEnhancer,
  ThunkAction,
  ThunkDispatch,
  applyMiddleware,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { api } from '../api';
import rootReducer, { RootState } from './reducer';

const middleware = [...getDefaultMiddleware<RootState>()];

const offlineConfig = {
  ...defaultOfflineConfig,
  persistOptions: {
    ...defaultOfflineConfig.persistOptions,
    blacklist: ['toasts'],
  },
  effect: (effect: AxiosRequestConfig) => {
    return api(effect);
  },
  discard: (error: { request: any; response: any }) => {
    const { request, response } = error;
    if (!request) throw error;
    return !!response;
  },
};

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware,
  enhancers: [applyMiddleware(), offline(offlineConfig) as StoreEnhancer],
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./reducer', () => {
    // eslint-disable-next-line global-require
    const newRootReducer = require('./reducer').default;
    store.replaceReducer(newRootReducer);
  });
}

export type AppDispatch = ThunkDispatch<RootState, unknown, Action<string>>;
export type AppThunk<R = void> = ThunkAction<
  R,
  RootState,
  unknown,
  Action<string>
>;

export const useTypedDispatch = (): AppDispatch => useDispatch<AppDispatch>();

interface AppState extends RootState {
  offline: OfflineState;
}

export const useTypedSelector = <TSelected>(
  selector: (state: AppState) => TSelected,
) => useSelector(selector, shallowEqual);

export default store;
