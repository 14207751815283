import { CssBaseline, ThemeProvider } from '@material-ui/core';
import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import ability from '../ability/ability';
import { User } from '../api/responses/signin.response';
import { AbilityContext } from '../components/Can';
import { NavigationBar } from '../components/Navbar';
import { SideBar } from '../components/Sidebar';
import { UncommittedRequestsNotification } from '../components/UncommittedRequestsNotification';
import { Routes } from '../enum/routes.enum';
import { push } from '../firebase';
import { Router } from '../router';
import { useTypedSelector } from '../store/store';
import { imgResolver } from './imgResolver';
import { returnLayoutOverridesStyles } from './layout-overrides-styles';
import styles from './Layout.module.scss';

push.init();

interface Props {
  user: User;
}

const mapStateToProps = (state: any) => ({
  user: state.user.userData,
});

const LayoutComponent: React.FC<Props> = ({ user }) => {
  const { authorized } = useTypedSelector(state => state.user);
  const activeSideBar = useTypedSelector(state => state.sidebar.active);
  const location = useLocation();
  const img = imgResolver(location.pathname);

  const theme = returnLayoutOverridesStyles(activeSideBar);

  let clazz = styles.background;

  if (
    location.pathname === Routes.Contractors ||
    location.pathname === Routes.Handbook
  )
    clazz = styles.backgroundWhite;

  return (
    <div>
      <img className={clazz} src={img} alt="backgroundimage" />
      <AbilityContext.Provider value={ability(user)}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {authorized && <NavigationBar />}
          <UncommittedRequestsNotification />
          {authorized && <SideBar />}
          <Suspense fallback={<div />}>
            <Router />
          </Suspense>
        </ThemeProvider>
      </AbilityContext.Provider>
    </div>
  );
};

export const Layout = connect(mapStateToProps)(LayoutComponent);
